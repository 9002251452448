import { useState } from "react";

import CookieConsent from "react-cookie-consent";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// Styles
import "./cookieBanner.styles.scss";

function CookieBanner() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    // <CookieConsent
    //   containerClasses="cookie-consent-banner"
    //   buttonText="Cerrar"
    //   //   debug="true"
    // >
    //   <div>this is cookie banner</div>
    // </CookieConsent>
    <CookieConsent
      location="bottom"
      buttonText="Cerrar"
      cookieName="myAwesomeCookieName2"
      style={{ background: "#2B373B" }}
      buttonStyle={{ color: "#4e503b", fontSize: "15px", fontWeight: "bolder" }}
      expires={150}
      containerClasses="notice"
      debug="true"
      //   disableButtonStyles="true"
    >
      <div className="notice_header">
        <h6>¡Advertencia!</h6>
      </div>
      <div className="notice_body">
        <p>
          Les informamos que por políticas de <span>calidad y seguridad</span>{" "}
          para nuestros clientes, los ejecutivos comerciales o cualquier persona
          que labora en &nbsp;
          <span className="green">
            afix servicios financieros, no solicitan ningún tipo de pago o
            compensasión
          </span>
          &nbsp; para efectos de realizar o agilizar su trámite crediticio con
          nuestra empresa.
        </p>
        <p>
          En caso de que a usted le sea requerido cualquier tipo de
          <span>&nbsp;pago</span>, por favor
          <span className="green">&nbsp;no lo realice y denúncielo</span> de
          inmediato al teléfono <a href="tel:+523336301888">33 36 30 18 88</a> o
          al correo&nbsp;
          <a href="mailto:informes@afix.com.mx">informes@afix.com.mx</a>
        </p>
        <a className="btn_information" onClick={handleShow}>
          Saber más
        </a>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Más información</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <p>
                Se recomienda <span>no</span> entregar documentos si no están
                seguros de que es una institución financiera legalmente
                reconocida por CONDUSEF.
              </p>
              <p className="my-3">
                Se sugiere desconfiar de aquellas instituciones que ofrezcan
                créditos preautorizados sin checar tu buró de crédito y con
                tasas muy bajas.
              </p>
              <p className="mb-0">
                Comprueba que los contratos de la Institución Financiera se
                encuentran registrados en el{" "}
                <a
                  href="https://registros.condusef.gob.mx/reca/_index.php"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  RECA.
                </a>
              </p>
            </div>
          </Modal.Body>
        </Modal>
        {/* <a
          className="btn_information"
          data-bs-toggle="collapse"
          href="#collapseNotice"
          role="button"
          aria-expanded="false"
          aria-controls="collapseNotice"
        >
          Saber más
        </a>
        <div className="collapse" id="collapseNotice">
          <div className="card card-body">
            <p>
              Se recomienda <span>no</span> entregar documentos si no están
              seguros de que es una institución financiera legalmente reconocida
              por CONDUSEF.
            </p>
            <p>
              Se sugiere desconfiar de aquellas instituciones que ofrezcan
              créditos preautorizados sin checar tu buró de crédito y con tasas
              muy bajas.
            </p>
            <p className="mb-0">
              Comprueba que los contratos de la Institución Financiera se
              encuentran registrados en el{" "}
              <a
                href="https://registros.condusef.gob.mx/reca/_index.php"
                target="_blank"
                rel="noopener noreferrer"
              >
                RECA
              </a>
            </p>
          </div>
        </div> */}

        <h5>afix servicios financieros no otorga créditos personales</h5>
        {/* <button
          className="bg-green-500 py-2 px-8 rounded text-white"
          onClick={() => acceptCookie()}
        >
          Cerrar
        </button> */}
      </div>
    </CookieConsent>
  );
}

export default CookieBanner;
