import React from "react";

// Assets
import igIcon from "../assets/images/ig-icon--green-2.svg";
import fbIcon from "../assets/images/fb-icon--green-2.svg";
import mailIcon from "../assets/images/mail-icon--green-2.svg";
import phoneIcon from "../assets/images/phone-icon--green-2.svg";
import waIcon from "../assets/images/wa-icon--green-2.svg";
import logo from "../assets/images/AFIX_logo.svg";

// Custom styles
import "../scss/pages/maintenance.styles.scss";

function Maintenance() {
  return (
    <main className="maintenance">
      <img src={logo} className="logo" alt="" />
      <div className="maintenance__container">
        <h1>
          El sitio está temporalmente fuera de servicio por mantenimiento.
        </h1>
        <p>
          Nuestra página web no está disponible temporalmente mientras
          realizamos mejoras. ¡Gracias por tu paciencia, estaremos de vuelta
          pronto!
        </p>
        <div className="maintenance__container__contact">
          <h3>Puede contactarnos a través de:</h3>
          <div className="maintenance__container__contact__list">
            <div>
              <a
                href="https://wa.me/+523331582842"
                target="_blank"
                rel="noreferrer"
              >
                <img src={waIcon} alt="" />
                (333)-158-2842
              </a>
            </div>
            <div>
              <a href="tel:+523336301888">
                <img src={phoneIcon} alt="" />
                (333)-630-1888
              </a>
            </div>
            <div>
              <a href="mailto:hola@afix.com.mx">
                <img src={mailIcon} alt="" />
                hola@afix.com.mx
              </a>
            </div>
            <div className="d-flex justify-content-center">
              <a
                href="https://www.instagram.com/afixfinanciera"
                target="_blank"
                rel="noreferrer"
              >
                <img src={igIcon} alt="" />
              </a>
              <a
                href="https://www.facebook.com/AFIXFinanciera"
                target="_blank"
                rel="noreferrer"
              >
                <img src={fbIcon} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="maintenance__footer">
        <p>
          Esta SOFOM se encuentra regulada por la CONDUSEF. AFIX SERVICIOS
          FINACIEROS, al ser una SOFOM E.N.R. y de conformidad al artículo 87-J
          de la Ley General de Organizaciones y Actividades Auxiliares del
          Crédito, no requiere autorización de la Secretaría de Hacienda y
          Crédito Público para su constitución y operación, y está sujeta a la
          supervisión o vigilancia de la Comisión Nacional Bancaria y de
          Valores.
        </p>
        <p>
          AFIX SERVICIOS FINANCIEROS, de acuerdo con el Articulo 95-bis de la
          Ley General de Organizaciones y Actividades Auxiliares del Crédito es
          vigilada y supervisada por la Comisión Nacional Bancaria y de Valores
          para el adecuado cumplimiento de las disposiciones relacionadas con la
          Prevención de Operaciones con Recursos de Procedencia Ilícita y
          Financiamiento al Terrorismo.
        </p>
      </div>
    </main>
  );
}

export default Maintenance;
